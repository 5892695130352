<template>
  <div>
    <div class="pageCode">
      <div style="z-index: 999; height: 585px; background-color: rgb(246, 94, 77); width: 351px; margin: -292.5px -175.5px; border-radius: 10px; overflow: hidden; position: fixed; top: 50%; left: 50%; animation: show3 .4s; overflow: hidden; ">
       <div style=" height: 460px; border: 1px solid rgb(246, 94, 77);  background-color: rgb(246, 94, 77); border-radius: 10px 10px 50% 50% / 10px 10px 16% 16%; box-shadow: 0px 4px 0px -1px rgba(0, 0, 0, 0.2); ">
         <p style="font-size: 20px;text-align: center;padding-top: 120px;color:gold;">天添法务的红包</p>
         <p style="font-size: 26px;text-align: center;padding-top: 20px;color: #ebcd9b;" v-if="redEnvelope.status==0">恭喜发财，大吉大利</p>
         <p style="font-size: 26px;text-align: center;padding-top: 20px;color: #ebcd9b;" v-if="redEnvelope.status==1">红包已派完了</p>
         <p style="font-size: 16px;text-align: center;padding-top: 20px;color: #ebcd9b;display: none;" v-if="redEnvelope.status==0">红包
           <span class="text-tip" style="margin-left: 14px; font-size: 12px; background-color: #ebcd9b; padding: 1px 4px; color: rgb(246, 94, 77)" v-if="redEnvelope.islingqu==0">可提现</span>
        </p>
      </div>
      <div v-if="redEnvelope.status==0 || redEnvelope.status==-1" style="width: 117px; height: 117px; border: 1px solid #ebcd9b;background-color: #ebcd9b; border-radius: 50%; 
	  margin:  -58.5px auto 0; box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2); text-align: center; font-size: 38px;color:black;font-weight:500;
	  line-height: 117px; animation: btn3 .5s; animation:1.8s ease 0s infinite normal none running btnShake3;animation-delay: .1s;" @click="openRedEnvelope($event)">
      <span style=" display: inline-block; font-size: 36px; font-family: SimSun; font-weight: bold; color: #333;-webkit-animation: scaling .6s infinite; animation: scaling .6s infinite;" >開</span>
	  </div>
      <div v-else style="color:gold;text-align: center;margin-top:50px;" @click="viewjilu()">
           红包已领取,查看领取记录>
      </div>
    </div>
    </div>
  </div> 
</template>

<script>
import {openUserInfoApi, posterApi,redEnvelopeIndexApi,receiveRedEnvelopeApi} from '@/api/redEnvelope.js'

export default {
  name: "index",
  data() {
    return {
      flag:true,
      redEnvelope: {employees:{}},
      dataList: {},
      userInfo: {},
      bgHeight: 'auto',
      base64Img: '',
      //    微信授权信息
      wxUserData: {},
      //  群聊信息
      roomNews: {},
      //  用户海报信息
      posterNews: {},
      canvasImg:'',
      parentUnionId: '',
      fissionId: 0,
      wxUserId: '',
      openflag:false
    }
  },

  created() {
    this.canvasImg = ''
    this.fissionId = this.$route.query.id;
    // 需要兼容老的query参数
    if (this.$route.query.parentUnionId !== undefined) {
      this.parentUnionId = this.$route.query.parentUnionId;
    } else if (this.$route.query.parent_union_id !== undefined) {
      this.parentUnionId = this.$route.query.parent_union_id;
    }

    if (this.$route.query.wxUserId !== undefined) {
      this.wxUserId = this.$route.query.wxUserId;
    } else if (this.$route.query.wx_user_id !== undefined) {
      this.wxUserId = this.$route.query.wx_user_id;
    }

    this.getOpenUserInfo();
  },
  methods: {
    callback(key) {
      console.log(key);
    },
    openRedEnvelope(el) {
        if(this.openflag)return;
        this.openflag=true;
		this.flag=false;
        let params = {
          id: this.fissionId,
          unionId: this.wxUserData.unionid,
          openId:this.wxUserData.openid
        }
        // alert(JSON.stringify(params));
        receiveRedEnvelopeApi(params).then((res) => {
            //{"code":200,"msg":"","data":{"code":0,"msg":"已经领取过"}}
            if(res.data.code==1){
                setTimeout(() => {
                     this.$router.push('/redEnvelope/redlist?id='+this.fissionId)
                },600);
           
           }else if(res.data.code==0){
                alert(res.data.msg);
                setTimeout(() => {
                     this.$router.push('/redEnvelope/redlist?id='+this.fissionId)
                },600);
            }else{
                alert('领取失败，请联系客服！');
                setTimeout(() => {
                     this.$router.push('/redEnvelope/redlist?id='+this.fissionId)
                },600);
            }
          // if (res.data.status == 0) {
          //   this.$message.info('活动已结束');
          //   return false
          // }
          // this.roomNews = res.data.room
        })
        
		  //this.$router.push('/redEnvelope/redlist?id='+this.$route.query.id+'&wxUserData=')
		// console.log(el);
  //       el.style.animation = 'btnMove .6s infinite alternate';
  //      setTimeout(function () {
  //          location.href = "view.html";
  //      }, 600);
    },
    viewjilu(){
        this.$router.push('/redEnvelope/redlist?id='+this.$route.query.id+'&wxUserData=')
    },
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
         let redirectUrl = '/auth/redEnvelope?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
         that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;
        this.getPosterData();
      });
    },
    //  获取海报数据
    getPosterData() {
      let params = {
        id: this.fissionId,
        unionId: this.wxUserData.unionid,
        openId:this.wxUserData.openid
      }
      
      redEnvelopeIndexApi(params).then((res) => {
          if(res.data.length==0){
            this.$message.info('这不是你的红包');  
          }else{
            this.redEnvelope=res.data;
            //this.redEnvelope.employee={nickname:'易发乐购'}  
          }
          
          if (res.data.status == 1) {
            this.viewjilu();
          }
        // if (res.data.status == 0) {
        //   this.$message.info('你好，很遗憾，本次活动已经结束，后续还会推出更多优惠活动，敬请期待！');
        //   return false
        // }
        // this.roomNews = res.data.room
      })
    }
  }
}
</script>

<style scoped lang="less">
.page {
  width: 100vw;
  height: 100vh;
  background-color:#ff0000 ;
  padding: 0 20px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  background-size: cover;
  overflow-y: auto;
  color:#ffffff;
  .top {
    .reply {
      padding-top: 16px;

      .content {
        padding: 16px 20px;
        border-radius: 16px;
        background-color: rgb(253, 253, 229);

        .title {
          display: flex;
          justify-content: space-between;

          .tip {
            color: saddlebrown;
            font-size: 15px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 7px;
              background-color: #b97e1229;
            }
          }

          .copy {

          }
        }

        .text {

        }
      }


    }

    .tips {
      padding: 20px 0;

      .tip {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        .text {
          position: relative;
          color: white;
          z-index: 0;

          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            z-index: -1;

            left: ((100% - 90%) / 2);

            height: 10px;
            width: 90%;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        .long-text {
          &::before {
            left: ((100% - 110%) / 2);
            width: 110%;
          }
        }
      }
    }

    .code-image {
      width: 100%;
      background-color: navajowhite;
      border-radius: 10px;
      box-shadow: inset 0 4px 6px 0 #0000001c;
      border: 2px solid white;
      padding: 10px;
      margin-top: 30px;
      .code-border {
        width: 100%;
        padding: 16px;
        background-color: white;
        border-radius: 12px;
      }
    }
  }

  .bottom-box {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 0 15px;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ff5636;
    display: flex;
    justify-content: center;

    .long-btn {
      width: 90%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: darkorange;
      font-size: 16px;
      border: 2px solid #ffd6a1;
      border-radius: 100px;
      background-color: #ffecdb;
    }
  }


}

.show_poster {
  width: 100%;
  height: 75vh;
  position: relative;
  //background: skyblue;
}

.cover_pic {
  width: 100%;
  height: 100%;
}

//.show_user_news {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  top: 0;
//}

.show_user_data {
  margin-left: 15px;
  margin-top: 15px;
  display: flex;
  position: absolute;
  top: 0;
}

.qr-code {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.user_name {
  margin-left: 10px;
  margin-top: 6px;
  font-size: 15px;
  font-weight: bold;
}

.user_qrCode {
  width: 50px;
  width: 50px;
}

//abc
.pageCode {
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.qr_room_img {
  width: 217px;
  height: 217px;
  //height: auto;
}

.qc_code {
  display: flex;
  justify-content: center;
  .code-box {
    margin-top: 50px;
    width: 90vw;
    height: auto;
    background-color: #ffffff;
    padding-bottom: 20px;
  }

  .top {
    //display: flex;
    //align-items: center;
    padding: 28px 28px 20px 28px;

    .left {
      margin-right: 15px;
    }
  }

  .shop_name {
    font-size: 17px;
    color: #222;
    font-weight: bold;
    text-align: center;
  }

  .shop_describe {
    color: #818181;
  }

  .shop_guide {
    margin-top: 17px;
    text-align: center;
    color: #818181;
  }
}
</style>
