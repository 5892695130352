import {request, request_op} from "../plugins/axios";

//海报
export function posterApi (params) {
    return request({
        url: '/redEnvelopeFission/poster',
        method: 'get',
        params: params
    })
}
export function inviteFriendsApi (params) {
    return request({
        url: '/redEnvelopeFission/inviteFriends',
        method: 'get',
        params: params
    })
}
export function taskDataApi (params) {
    return request({
        url: '/redEnvelopeFission/taskData',
        method: 'get',
        params: params
    })
}
export function receiveApi (params) {
    return request({
        url: '/redEnvelopeFission/receive',
        method: 'get',
        params: params
    })
}
export function receiveRedEnvelopeApi (params) {
    return request({
        url: '/redEnvelope/receive',
        method: 'get',
        params: params
    })
}
//获取微信用户信息
export function openUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/redEnvelope',
        method: 'GET',
        params: params
    })
}

//获取微信用户信息
export function openUserInfoFissionApi (params) {
    return request_op({
        url: '/openUserInfo/redEnvelopeFission',
        method: 'GET',
        params: params
    })
}

export function redEnvelopeApi (params) {
    return request({
        url: '/redEnvelope/getRedEnvelopelog',
        method: 'GET',
        params: params
    })
}

export function redEnvelopeIndexApi (params) {
    return request({
        url: '/redEnvelope/index',
        method: 'POST',
        params: params
    })
}

export function redEnvelopeFissionLogApi (params) {
    return request({
        url: '/redEnvelopeFission/redEnvelopeFissionLog',
        method: 'POST',
        params: params
    })
}

export function redEnvelopeLinkApi (params) {
    return request({
        url: '/redEnvelopeFission/show',
        method: 'GET',
        params: params
    })
}
